import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import useAuth from "hooks/auth";
import strings from 'utils/strings';
import clsx from 'clsx';
import {ClickAwayListener} from '@mui/base';
import React, {useEffect, useRef, useState} from 'react';
import {openDeposit, openVerificationModal} from "store/modals/actions";
import {useDispatch, useSelector} from 'react-redux';
import styles from 'themes/spins/styles/balance.module.scss'
import {navigateToPendingTickets} from "store/common/actions";
import CircularProgress from '@mui/material/CircularProgress';
import {getDocumentValidation, getWithdrawal} from "services/account";
import {validationTypes} from "utils/constants";
import candyQuestLvs from "../../public/json/static/quests.json";
import moment from 'moment';
import ArrowRight from "@/template/svg/arrow-right";
import AddIconSpin from 'themes/spins/svg/AddIconSpin';
import VerificationStatus from "@/AbsoluteComponents/interfaces/VerificationStatus";
import useCommon from "@/AbsoluteComponents/hooks/common";
import {hardcoded_quest} from "@/template/config/constants";
import CMS from "@/AbsoluteComponents/utils/cms";
import {bonus_types} from "@/AbsoluteComponents/interfaces/types";
import Chevrone from "@/template/svg/Chevrone";
import DummyRange from "@/template/ui/DummyRange";
import InfoIcon from "@/template/svg/info";
import Game from "@/AbsoluteComponents/utils/game";
import GameInterface from "@/AbsoluteComponents/interfaces/game";
import Card from "@/template/ui/game/card";
import SportIconSpins from "@/AbsoluteComponents/themes/spins/svg/sportIconSpins";
import Button from "@/template/small_ui/button";

const SpinsBalanceView:React.FC = ({ ... blProps}) => {

    const data = useAuth();
    const router = useRouter();
    const progress = data.getWithdrawProgress();
    const dispatch = useDispatch();
    const freeBonus = data.getFreeBonuses();
    const freeSpins =  data.getFreeSpins();
    const common_hook = useCommon()
    const betBalance = useSelector((state:any) => state.account.betBalance)
    const leaderboard: any = useSelector((state:any) => state.account.questLeaderboard)

    const openPendingWithdraw = () => {
        router.push('/account/transactions/withdrawal?status=pending');
    };


    const changeClass = () => {
        localStorage.setItem('bonus', freeBonus.toString());
        localStorage.setItem('spins', freeSpins.toString());

        const styleClass = document.querySelector('#myDIV');
        let removeStyle =  styleClass?.classList.remove('btnBalance-activeSpins');

    }

    const [open, setOpen] = useState(false);
    const [transactionsCount, setTransactionsCount] = useState(0);

    const getPendingWithdraw = async () => {
        const data = await getWithdrawal(0, 10, 'pending', moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss'),  moment().format('YYYY-MM-DD HH:mm:ss'));

        if (!data.error) {
            setTransactionsCount(data.response?.transactions?.length || 0)
        }
    };

    useEffect(() => {
        setOpen(false);
    }, [router.pathname]);

    useEffect(() => {
        if (open) {
            getPendingWithdraw();
            
            if(window.innerWidth < 700) {
                document.body.classList.add('hideZendesk');
            }
            
        } else {
            setTransactionsCount(0);
            
            if(window.innerWidth < 700) {
                document.body.classList.remove('hideZendesk');
            }
        }
    }, [open]);

    // const [documents, setDocument] = useState([]);

    // const fetchValidation = async () => {
    //
    //     const result:any = [];
    //
    //     const documents = await getDocumentValidation();
    //
    //     if(!documents.error) {
    //         documents.response.map((item:any) => {
    //             result.push({
    //                 documentName: typeof item['documentName'] === 'string' ? item['documentName'] : '',
    //                 status: typeof item['status'] === 'string' ? item['status'] : '',
    //                 type: typeof item['type'] === 'string' ? item['type'] : '',
    //                 updatedTime: typeof item['updatedTime'] === 'number' ? item['updatedTime'] : 0,
    //                 uploadedTime: typeof  item['uploadedTime'] === 'number' ? item['uploadedTime'] : 0,
    //                 verificationType:typeof  item['verificationType'] === 'string' ? item['verificationType'] : 0,
    //             })
    //         })
    //
    //         setDocument(result);
    //     }
    // }
    //
    //
    // useEffect(() => {
    //     fetchValidation()
    // }, []);

    // const types:Array<string> = validationTypes.map(item => item.key);

    // let approved = documents.find((item:any) => {
    //     return item.status === 'approved' && types.includes(item.type) ;
    // });

    // const status:boolean = documents.length === 0 ? true : approved !== undefined;
    let allVerified:boolean = true;
    
    const verificationStatus:Array<VerificationStatus> = useSelector((state:any) => state.account.verificationStatus);
    // const opened:Array<VerificationStatus> = useSelector((state:any) => state.account.validation_opened);
    
    verificationStatus.map(item => {
        if (!item.verified) allVerified = false;
    });

    let userCandyStatus = data.getCandyQuestStatus()
    //ADDED START  ADDED START  ADDED START  ADDED START  ADDED START  ADDED START
    
    let currentLevel:any = {}
    
    if(userCandyStatus.level !== 0) {
       currentLevel = candyQuestLvs.find((lv:any) => {return lv.level === userCandyStatus.level})
    } else {
        currentLevel = {
            level: 0,
            points: 0
        }
    }
    
    const getPercentage = (): number => {

        let minPoints:number = (currentLevel?.points as number);
        let maxPoints:any = candyQuestLvs[userCandyStatus.level].points
        let currentLvUserPoints:number = userCandyStatus.points - minPoints;

        return common_hook.getPercentage(minPoints, maxPoints, currentLvUserPoints);
    }

    let balance_parts = data.getUserBalance().toFixed(2).split('.')

    let output_balance = {
        a: balance_parts[0],
        b: `.${balance_parts[1] || ''}`,
    };
    
    let blink_balance = data.getFreeBets() > 0 || data.getFreeBonuses() > 0 || data.getFreeSpins() > 0
    
    return (
        <>

            {open && (
                <div onClick={() => setOpen(false)} className={styles.drop_down_overlay}>

                </div>
            )}
            
            <ClickAwayListener onClickAway={() => {
                if(window.innerWidth > 450) {
                    setOpen(false);
                }
            }}>
                <div className={clsx(styles.dropDownBalanceWrapper,
                    open && 'themes_balance_wrapper__open'
                )}>

                    <button
                        id="myDIV"
                        className={clsx(
                            styles.btnBalance,
                            'themes_balance_wrapperHeader',
                            open && 'bgOpenUserDrop',
                            blink_balance && styles.btnBalance_activeSpins
                        )}
                        onClick={() => {
                            setOpen(!open);
                            changeClass();

                        }}
                    >

                        <span className={clsx(styles.useBalanceWrapper)}>
                            <span style={{color: 'white'}} className={clsx(styles.useBalance, 'theme_textColor_secondary')}>
                                {output_balance.a}{output_balance.b}
                            </span>
                            
                            <span className={styles.balanceCurrency}>
                                {data.getCurrency()}
                            </span>
                        </span>

                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                dispatch(openDeposit(true));
                            }}
                            style={{border: '1px solid transparent'}}
                            className={styles.btn_balance_add}
                        >
                            <span className={clsx('addIconBalance', 'themeAddIcon')}>
                                <AddIconSpin />
                            </span>
                        </div>

                    </button>

                    <div className={clsx(styles.dropDownBalance, open && styles.active, 'theme_dropDownBalance')}>
                        <div className={clsx(styles.dropDownBalance__items,  'theme_dropDownBalance__item')}>
                            <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>{strings['balanta_totala']}</p>
                            <div className={styles.dropDownBalance__data}>
                                <span className={styles.dropDownBalance__dataSpan}>{data.getUserBalance()}</span>
                                <span className={clsx(styles.dropDownBalance__currency, 'theme__headerContainer__list')}>
                                {data.getCurrency()}
                            </span>
                            </div>
                        </div>

                        <div className={clsx(styles.dropDownBalance__cash,)}>
                            <div className={clsx(styles.dropDownBalance__cashAm, styles.dropDownBalance__items, 'theme_dropDownBalance__item')} style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                WebkitAlignItems: 'center'
                            }}>
                                <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>{strings['bani_cash']}</p>
                                <p className={styles.dropDownBalance__data}>
                                    <span className={styles.dropDownBalance__dataSpan}>
                                        {data.getCash()}
                                    </span>
                                    <span className={clsx(styles.dropDownBalance__currency, 'theme__headerContainer__list')}>
                                        {data.getCurrency()}
                                    </span>
                                </p>
                            </div>
                            {(typeof data.getBonus() === "number" && data.getBonus() === 0) && (
                                <div className={clsx(styles.dropDownBalance__items, 'theme_dropDownBalance__item')}>
                                    <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>{strings['bonus']} </p>
                                    <p className={styles.dropDownBalance__data}>
                                         <span className={styles.dropDownBalance__dataSpan}>
                                            {data.getBonus()}
                                         </span>
                                        <span
                                            className={clsx(styles.dropDownBalance__currency, 'theme__headerContainer__list')}>
                                            {data.getCurrency()}
                                        </span>
                                    </p>
                                </div>
                            )}
                            
                            
                            <Button
                                className={styles.deposit_btn}
                                text={strings["deposit"]}
                                onClick={() => {
                                    dispatch(openDeposit(true))
                                    setOpen(false)
                                }}
                            />
                        </div>
                        
                        {/*ADDED START  ADDED START  ADDED START  ADDED START  ADDED START  */}
                        
                        {!CMS.getCurrentQuest().hardcoded ? (
                            userCandyStatus.hasOwnProperty('optIn') &&
                            userCandyStatus?.optIn &&
                            leaderboard?.state.hasOwnProperty("status") &&
                            leaderboard?.state?.status === "Running"
                        ) && (
                            
                            <div className={clsx(styles.questBalance, styles.clickable)}>
                                
                                <div
                                    onClick={() => {
                                        router.push('/'+CMS.getCurrentQuest().url_id)
                                    }}
                                    className={clsx(
                                        styles.questBalance__section,
                                        styles.section1,
                                        'theme_dropDownBalance__item',
                                        'clickable',
                                        router.asPath === '/candy-quest' && 'selected'
                                    )}>

                                    <div className={styles.questBalance__headings}>
                                        <h4 style={{textTransform: "capitalize"}} className={'theme_textColor_secondary'}>
                                            Aventura {CMS.getCurrentQuest().name}
                                        </h4>
                                        <span className={styles.score}>
                                            {userCandyStatus.level && (
                                                <span className={styles.yellow}>
                                                    {userCandyStatus.levelName}
                                                </span>
                                            )}
                                                /{candyQuestLvs.length}
                                        </span>
                                    </div>

                                    <div style={{margin: 0}}
                                         className={clsx(styles.dropDownBalance__progress, 'theme_score_bg')}>

                                        <p
                                            style={{margin: 0}}
                                            className={clsx(
                                                styles.progressData,
                                                'theme_white_dark_text'
                                            )}
                                        >
                                            Nivelul {userCandyStatus.level}

                                            <span style={{marginLeft: '10px'}}>
                                            <>
                                                {data.currentUserQuestPercentage()}%
                                            </>
                                        </span>
                                        
                                        </p>

                                        <div className={'theme_yellow_bg'}
                                             style={{height: 24, borderRadius: '30px', width: `${data.currentUserQuestPercentage()}%`}}
                                        />
                                    </div>
                                </div>

                            </div>
                        ) : null}

                        {/*ADDED END  ADDED END  ADDED END  ADDED END  ADDED END  ADDED END  */}

                        
                        
                        <BonusList data={data}/>

                        {betBalance > 0 ? (
                            <div
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    router.push('/account/transactions/betting?status=pending')
                                    dispatch(navigateToPendingTickets(true))
                                }}
                                className={clsx(
                                    styles.dropDownBalance__items,
                                    'theme_dropDownBalance__item' ,
                                    'clickable',
                                )}
                            >
                                <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>
                                    {strings['balanta-pariuri']}
                                </p>

                                <p className={styles.dropDownBalance__data}>
                                    {betBalance}
                                    <span style={{marginLeft: '2px'}} className={clsx(styles.dropDownBalance__currency, 'theme__headerContainer__list')}>
                                    {data.getCurrency()}
                                </span>
                                </p>

                            </div>
                        ) : null}
                        
                        
                        
                        {!data?.getUser()?.kycVerified ?
                            (<Link
                                aria-label={strings["ver_identity"]}
                                href={'/account/account-validation'}
                                className={clsx(
                                    styles.balanceValidation,
                                    styles.dropDownBalance__items,
                                    'theme_dropDownBalance__item',
                                    'clickable',
                                    router.asPath === '/account/account-validation'  && 'selected'
                                )}
                            >

                                <p
                                    style={{margin: '8px 0', fontWeight: 600}}
                                    className={clsx(styles.dropDownBalance__title)}
                                >
                                    {strings["ver_identity"]}
                                </p>

                            </Link>) : null
                        }
                        
                        {!allVerified &&
                            <div
                                style={{cursor: "pointer"}}
                                onClick={() => {dispatch(openVerificationModal(true))}}
                                className={clsx(
                                    styles.balanceValidation,
                                    styles.dropDownBalance__items,
                                    'theme_dropDownBalance__item',
                                    'clickable',
                                )}
                            >

                                <p
                                    style={{margin: '8px 0', fontWeight: 600}}
                                    className={clsx(styles.dropDownBalance__title)}
                                >
                                    {strings["ver_email"]}
                                </p>

                            </div>
                        }

                        {transactionsCount > 0 &&
                            <Link
                                aria-label={"Retrageri"}
                                href={'/account/transactions/withdrawal?status=pending'}
                                style={{cursor: 'pointer'}}
                                className={clsx(
                                    styles.dropDownBalance__items,
                                    'theme_dropDownBalance__item' ,
                                    'clickable',
                                )}
                            >
                                <div className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>
                                    Retrageri în așteptare
                                </div>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className={styles.dropDownBalance__data}>
                                        <span className={styles.dropDownBalance__dataSpan}>{transactionsCount}</span>
                                    </div>
                                    <div className={'theme_text_mainColor'}>
                                        <ArrowRight />
                                    </div>
                                </div>
                                
                            </Link>
                        }
                    </div>

                </div>

            </ClickAwayListener>
        </>
    )
}

interface BonusListProps {
    data:any
}

const BonusItem: React.FC<{
    title: string;
    value: number | string;
    onClick: () => void;
    bonusContainerRef: React.RefObject<any>;
    bonusClassName: string;
    dataStyleClass: string;
    type:bonus_types
    bonus_obj:any
}> = (props) => {
    
    const {
        title,
        value,
        onClick,
        bonusContainerRef,
        bonusClassName,
        dataStyleClass,
        type,
        bonus_obj
    } = props
    
    const router = useRouter()
    

    
    return (
        
        <div ref={bonusContainerRef} className={styles.main_item_component}>
            
            <div
                
                style={{textDecoration: 'none'}}
                className={clsx(
                    styles.dropDownBalance__items,
                    'theme_dropDownBalance__item',
                    // router.asPath === '/free-spins' && 'selected',
                    'clickable',
                    styles.dropDownBalance__itemsSpins,
                    styles.spinsStyle,
                    styles.dropdown
                )}
                title={strings['rotiri_gratuite']}
            >
                
                <div onClick={onClick} className={styles.head}>
                    <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>
                        {title}
                    </p>
                    
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span className={clsx(styles.dropDownBalance__data,)}>
                            {value}
                        </span>
                        
                        <div className={'theme_text_mainColor'}>
                            <ArrowRight/>
                        </div>
                    </div>
                </div>
                
                <div className={styles.bonus_container}>
                    <BonusView bonus_obj={bonus_obj} type={type as bonus_types}/>
                </div>
            
            </div>
        
        
        </div>
    )
}

const BonusView: React.FC<{ type: bonus_types, bonus_obj: any }> = ({type, bonus_obj}) => {
    
    const common_hook = useCommon()
    const auth = useAuth()
    const router = useRouter()
    
    switch (type) {
        case bonus_types.Bonus:
            
            return (
                <div className={styles.bonus_type_bonus}>
                    {(Array.isArray(bonus_obj) && bonus_obj.length) ? bonus_obj.map((x, i) => {
                        
                        const percentage = common_hook.getPercentage(0, x?.turnOverThreshold / 100, x?.turnOver / 100)
                        
                        return (
                            <div
                                key={`balance_bonus_${i}`}
                                className={styles.bonuses}
                            >
                                <div className={styles.range}>
                                    <span>
                                        Bonus {i + 1} - <span className={styles.yellow}>{percentage || 0}%</span>
                                    </span>
                                    
                                    <DummyRange
                                        denyValueLabel
                                        no_paddings
                                        max={(x?.turnOverThreshold / 100) || 0}
                                        value={(x?.turnOver / 100) || 0}
                                    />
                                </div>
                                
                                <span className={clsx(styles.yellow, styles.amn)}>
                                    {x?.amount.toFixed(2)} RON
                                </span>
                            </div>
                        )}
                    ) : null}
                    
                    <span className={styles.inf}>
                        
                        <InfoIcon /> Bonusurile vor fi plătite în proporție de 100%.
                    </span>
                </div>
            )
        
        case bonus_types.FreeBets:
            return (
                <div className={styles.bonus_type_free_bets}>
                    {(Array.isArray(bonus_obj) && bonus_obj.length) ? bonus_obj.map((x, i) => {
                        
                        return (
                            <div
                                key={`balance_free_bet_${i}`}
                                className={clsx(styles.bet)}
                                onClick={() => router.push("/sport")}
                            >
                                
                                <div className={styles.ticket_start}>
                                    
                                    <SportIconSpins />
                                    
                                    <span className={clsx(styles.cuts, styles.top, 'theme_dropDownBalance__item')}/>
                                    
                                    <span className={clsx(styles.cuts, styles.bottom, 'theme_dropDownBalance__item')}/>
                                
                                </div>
                                
                                <div className={styles.value}>
                                    {x?.accountingExtraData?.betAmount.toFixed(2)} RON
                                </div>
                                
                                <div className={styles.badge}>
                                    {x?.grantedAmount}
                                </div>
                            
                            </div>
                        )
                    }) : null}
                </div>
            )
        
        case bonus_types.TimedFreeSpins:
            
            return (
                <div className={styles.bonus_type_free_spins}>
                    
                    {(Array.isArray(bonus_obj) && bonus_obj.length) ? bonus_obj.map((x, i) => {
                        
                        const game: any = Game.getAll().find((g: GameInterface) => g.gameId === x.games[0]?.gameId);
                        
                        return game ? (
                            <div
                                key={`balance_free_spins_${i}`}
                                className={styles.game}
                                onClick={(e: any) => auth.runGame(game, false, () => {
                                }, e)}
                            >
                                <Card
                                    disableDemo
                                    hideDetails={true}
                                    hide_inner
                                    game={game}
                                />
                                
                                <div className={clsx(styles.badge, styles.g)}>
                                    {x?.interval} sec.
                                </div>
                            </div>
                        ) : null
                    }) : null}
                </div>
            )
        
        case bonus_types.FreeSpins:
            return (
                <div className={styles.bonus_type_free_spins}>
                    {(Array.isArray(bonus_obj) && bonus_obj.length) ? bonus_obj.map((x, i) => {
                        
                        const game: any = Game.getAll().find((g: GameInterface) => g.gameId === x.games[0]?.gameId);
                        
                        return (
                            <div
                                key={`balance_free_spins_${i}`}
                                className={styles.game}
                                onClick={(e: any) => auth.runGame(game, false, () => {
                                }, e)}
                            >
                                <Card
                                    disableDemo
                                    hideDetails={true}
                                    hide_inner
                                    game={game}
                                />
                                
                                <div className={clsx(styles.badge, styles.g)}>
                                    {x?.grantedAmount}
                                </div>
                            </div>
                        )
                    }) : null}
                </div>
            )
        
        case bonus_types.FreeBonus:
            
            const game: any = bonus_obj?.game ? Game.getAll().find((g: GameInterface) => g.gameId === bonus_obj?.game?.gameId) : undefined
            
            
            return (
                <div className={styles.bonus_type_free_spins}>
                    {(Array.isArray(bonus_obj) && bonus_obj.length) ? bonus_obj.map((x, i) => {
                        
                        const game: any = Game.getAll().find((g: GameInterface) => g.gameId === x.game.gameId);
                        
                        return game ? (
                            <div
                                key={`balance_free_spins_${i}`}
                                className={styles.game}
                                onClick={(e: any) => auth.runGame(game, false, () => {}, e)}
                            >
                                <Card
                                    disableDemo
                                    hideDetails={true}
                                    hide_inner
                                    game={game}
                                />
                                
                                <div className={clsx(styles.badge, styles.g)}>
                                    {x?.grantedAmount}
                                </div>
                            </div>
                        ) : null
                    }) : null}
                </div>
            )
        default:
            return <></>
    }
}

const BonusList: React.FC<BonusListProps> = ({data}) => {
    const ref0 = useRef<any>(null);
    const ref1 = useRef<any>(null);
    const ref2 = useRef<any>(null);
    const ref3 = useRef<any>(null);
    const ref4 = useRef<any>(null);
    const new_wallet = useSelector((state: any) => state.account.new_wallet);
    
    const bonusItems = [
        {
            visible: Array.isArray(new_wallet?.bonuses) && new_wallet?.bonuses.length,
            title: "Bonus",
            value: (() => {
                try {
                    if (Array.isArray(new_wallet?.bonuses)) {
                        return new_wallet?.bonuses.reduce((sum: any, x: any) => sum + x?.amount, 0)
                    } else return 0
                    
                } catch (e) {
                    console.error(e)
                    return 0
                }
                
            })().toFixed(2) + " RON",
            bonusClassName: styles.firstStyle,
            bonusContainerRef: ref0,
            dataStyleClass: Array.isArray(new_wallet?.bonuses) && new_wallet?.bonuses.length ? styles.dropDownBalance__dataStyle : "",
            type: bonus_types.Bonus,
            bonus_obj: new_wallet?.bonuses
            
        },
        {
            visible: data.getFreeSpins() > 0,
            title: strings['rotiri_gratuite'],
            value: data.getFreeSpins(),
            bonusClassName: styles.spinsStyle,
            bonusContainerRef: ref1,
            dataStyleClass: data.getFreeSpins() === 0 ? '' : styles.dropDownBalance__dataStyle,
            type: bonus_types.FreeSpins,
            bonus_obj: new_wallet?.freeSpins
        },
        {
            visible: data.getFreeBonuses() > 0,
            title: 'Speciale Gratuite',
            value: data.getFreeBonuses(),
            bonusClassName: styles.bonusStyle,
            bonusContainerRef: ref2,
            dataStyleClass: data.getFreeBonuses() === 0 ? '' : styles.dropDownBalance__dataStyle,
            type: bonus_types.FreeBonus,
            bonus_obj: new_wallet?.freeBonuses
        },
        {
            visible: data.getFreeBets() > 0,
            title: strings['pariuri_gratuite'],
            value: data.getFreeBets(),
            bonusClassName: styles.betStyle,
            bonusContainerRef: ref3,
            dataStyleClass: data.getFreeBets() === 0 ? '' : styles.dropDownBalance__dataStyle,
            type: bonus_types.FreeBets,
            bonus_obj: new_wallet?.freeBets
        },
        {
            visible: data.getTimedFreeSpins() > 0,
            title: strings['rotiri_gratuite_pe_timp'],
            value: `${data.getTimedFreeSpins()} sec.`,
            bonusClassName: styles.spinsStyle,
            bonusContainerRef: ref4,
            dataStyleClass: styles.dropDownBalance__dataStyle,
            type: bonus_types.TimedFreeSpins,
            bonus_obj: new_wallet?.timedFreeSpins
        }
    ];
    
    const atLeastOneVisible = bonusItems.some(item => item.visible);
    
    return (
        
        <>
            {atLeastOneVisible ? (
                <span className={styles.bonus_text}>
                    Bonusurile tale:
                </span>
            ) : null}
            
            <div className={styles.bonus_list}>
                {bonusItems.map((item, index) =>
                    item.visible ? (
                        <React.Fragment key={`balance_bonus_${index}`}>
                            <BonusItem
                                title={item.title}
                                value={item.value}
                                onClick={() => {
                                    item.bonusContainerRef.current?.classList.toggle(styles.open)
                                }}
                                bonusContainerRef={item.bonusContainerRef}
                                bonusClassName={item.bonusClassName}
                                dataStyleClass={item.dataStyleClass}
                                type={item.type}
                                bonus_obj={item.bonus_obj}
                            />
                        </React.Fragment>
                    ) : null
                )}
            </div>
        </>
    
    );
};


export default SpinsBalanceView;

