import CMS from "@/AbsoluteComponents/utils/cms";
import JPInit from "@/AbsoluteComponents/jp_init";
import AllJpInterface from "@/AbsoluteComponents/interfaces/all_jp_interface";
import {useSelector} from "react-redux";
import JackpotInterface from "@/AbsoluteComponents/interfaces/jp_interface";
import {fever_types, jackpot_types} from "@/AbsoluteComponents/interfaces/types";
import st from '@/template/styles/OnPageJP.module.scss';
import clsx from "clsx";
import React, {useEffect, useRef, useState} from "react";
import LazyImage from "@/AbsoluteComponents/ui/LazyImage";
import Image from "next/image";
import Skeleton from "@mui/material/Skeleton";
import {Swiper, SwiperSlide} from "swiper/react";
import RoundedLeveling from "@/template/ui/RoundedLeveling";
import JackpotCounterDunamic from "@/template/cms-views/jpFever/jackpot_counter_dynamic";
import styles from "@/template/styles/JpFever.module.scss";
// @ts-ignore
import numberFormatter from 'number-formatter';
import moment from "moment";
import strings from "@/AbsoluteComponents/utils/strings";
import GameInterface from "@/AbsoluteComponents/interfaces/game";
import Game from "@/AbsoluteComponents/utils/game";
import BurningBox from "@/template/small_ui/BurningBox";

interface Props {
    json: any
}

const OnPageJP:React.FC<Props> = ({json}) => {
    const [swiper, setSwiper] = useState<any>();
    const data  = CMS.getAllFields(json)
    const {jp_key} = data
    const decorations = useRef<any>(null)
    const all_jackpots:AllJpInterface | undefined = useSelector((state:any) => state.common.all_jackpots);
    const topValsStyle = {maxHeight: "20px", width: `fit-content`}
    let skeletonLength = (jp_key as jackpot_types ) !== "hotLuck" ?  4 : 3
    let skeletonArray: Array<any> = []
    const allGames:Array<GameInterface> = Game.getAll();
    const gamesFound:Array<GameInterface> =  allGames.filter((g:any) => g.categories.includes(data.category_id ? data.category_id : data.category_id_));
    // console.log(data)
    
    for (let i = 0; i < skeletonLength; i++) {
        skeletonArray.push(i)
    }
    
    const get_local = () => {
        
        let res:JackpotInterface | undefined = undefined
        
        if(typeof jp_key === "string" && jp_key.length && all_jackpots) {
            res = all_jackpots[jp_key as jackpot_types]
        }
        
        return res
    }
    
    const local_jp = get_local()
    // console.log("local_jp", local_jp)
    const is_local_jp = typeof local_jp !== "undefined"
    
    
    useEffect(() => {
        if(is_local_jp) {
            
            // if(decorations.current) decorations.current.style.opacity = "1"
        }
    }, [local_jp])
    
    
    return (
        <>
            <JPInit />
            
            <section className={clsx(st.main, (is_local_jp && local_jp.jackpot_name) && st[local_jp.jackpot_name])}>
                
                <div className={st.content}>
                    
                    <div className={clsx(st.header, "theme_white_dark_text")}>
                        {Array.isArray(gamesFound) ? (
                            <span className={st.games}>
                                {gamesFound.length === 1 ? gamesFound.length + " joc" : gamesFound.length + " jocuri"}
                            </span>
                        ) : null}
                        
                        <div className={st.img_container}>
                            
                            <Image
                                src={`/spins/images/JackpotsLive/logos/${jp_key}.webp`}
                                alt={"jackpot"}
                                height={120}
                                width={312}
                                layout={"responsive"}
                                className={st.jp_logo}
                            />
                        
                        </div>
                        
                        <div className={st.divider}>
                        
                        </div>
                        
                        <div className={st.end}>
                            {is_local_jp ? (
                                <>
                                
                                    <Image
                                        data-hide-on-theme="dark"
                                        src={`/spins/images/JackpotsLive/providers/${local_jp?.provider}_dark.webp`}
                                        alt={"jp provider"}
                                        width={123}
                                        height={56}
                                    />
                                    
                                    <Image
                                        data-hide-on-theme="light"
                                        src={`/spins/images/JackpotsLive/providers/${local_jp?.provider}_light.webp`}
                                        alt={"jp provider"}
                                        width={123}
                                        height={56}
                                    />
                                </>
                            ) : (
                                <Skeleton
                                    sx={{
                                        width: "75px",
                                        height: "32px",
                                    }}
                                    animation="wave"
                                    variant="rectangular"
                                />
                            )}
                        </div>
                    
                    
                    </div>
                    
                    <div className={st.body}>
                        
                        <Bretton />
                        
                        <div className={st.inner}>
                            
                            <Swiper
                                className={clsx(st.slider)}
                                spaceBetween={32}
                                slidesPerView={"auto"}
                                onSwiper={(sw: any) => {
                                    setSwiper(sw)
                                }}
                                breakpoints={{
                                    1400: {
                                        spaceBetween: 20
                                    },
                                    0: {
                                        spaceBetween: 20,
                                    },
                                }}
                            >
                                
                                {is_local_jp ? (
                                    <>
                                        {Array.isArray(local_jp?.levels) ? local_jp?.levels.map((x, i) => {
                                            
                                            return (
                                                <SwiperSlide
                                                    key={'jackpot_level' + i}
                                                    className={clsx(st.slide)}
                                                >
                                                    <div className={st.jp_level}>
                                                        
                                                        <LazyImage
                                                            src={`/spins/images/JackpotsLive/pure_cards/${x.fever}.webp`}
                                                            alt={"level"}
                                                            className={st.image}
                                                            // width={292}
                                                            // height={340}
                                                        />
                                                        
                                                        <div className={st.jv_info}>
                                                            
                                                            <div className={st.top}>
                                                                <RoundedLeveling
                                                                    value={x.percentage}
                                                                    fever={x.fever}
                                                                    className={st.leveling}
                                                                />
                                                                
                                                                <strong>
                                                                    {x.lv_name}
                                                                </strong>
                                                            </div>
                                                            
                                                            <BurningBox
                                                                main_box_class={st.center_container}
                                                                dark_box_class={st.center}
                                                                glowing={x.percentage > 95}
                                                            >
                                                                <div className={st.val}>
                                                                    
                                                                    <JackpotCounterDunamic
                                                                        id={"counter_top"}
                                                                        textWidth={12}
                                                                        textHeight={20}
                                                                        style={topValsStyle}
                                                                        className={clsx(styles.valueContainer, st.valueContainer)}
                                                                        jackpot={local_jp}
                                                                        level={x}
                                                                        value={x.value && x.value}
                                                                        on_jp_page
                                                                        static_counter={x.hasOwnProperty("static") ? x.static : false}
                                                                    />
                                                                    
                                                                    <span className={st.ron}>
                                                                        RON
                                                                    </span>
                                                                
                                                                </div>
                                                                
                                                                
                                                                <div
                                                                    className={clsx(st.badge, st[x.fever])}
                                                                >
                                                                    {strings[x.fever]}
                                                                </div>
                                                            </BurningBox>
                                                            
                                                            <div className={st.numbers}>
                                                                
                                                                <div className={clsx(st.latest, st.nm)}>
                                                                     <span>
                                                                        Ultimul câștig
                                                                     </span>
                                                                    
                                                                    <strong>
                                                                        {x.last_win ? numberFormatter("#.##0,####", x.last_win) + " RON" : "N/A"}
                                                                    </strong>
                                                                </div>
                                                                
                                                                <div className={clsx(st.latest_date, st.nm)}>
                                                                     <span>
                                                                        Data câștigului
                                                                     </span>
                                                                    
                                                                    <strong>
                                                                        {(typeof x.latest_win_date === 'string' && x.latest_win_date.length) ? moment(x.latest_win_date).format('DD/MM/YYYY') : "N/A"}
                                                                    </strong>
                                                                </div>
                                                            
                                                            
                                                            </div>
                                                        
                                                        
                                                        </div>
                                                        
                                                        <div className={st.bottom}/>
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }) : null}
                                    </>
                                ) : (
                                    <>
                                        {skeletonArray.map((x, i) => {
                                            return (
                                                <SwiperSlide
                                                    key={'jackpot_skeleton' + i}
                                                    className={clsx(st.slide)}
                                                >
                                                    <div className={clsx(st.jp_level, st.skeleton)}>
                                                        <Skeleton
                                                            sx={{
                                                                width: "100%",
                                                                height: "100%",
                                                            }}
                                                            animation="wave"
                                                            variant="rectangular"
                                                        />
                                                    
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </>
                                )}
                            
                            </Swiper>
                            
                        </div>
                    </div>
                
                </div>
                
            </section>
        </>
    )
}


const Bretton = () => {
    return (
        <>
            <svg className={st.bretton} data-hide-on-theme="dark" width="427" height="74" viewBox="0 0 427 74" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M426.189 0H0.000370026L0 19C14.5672 19 28.6369 24.2996 39.5841 33.9101L62.5064 54.0335C77.1027 66.8475 95.8624 73.9137 115.285 73.9137L310.903 73.9137C330.326 73.9137 349.086 66.8475 363.682 54.0335L386.604 33.9102C397.552 24.2996 411.621 19 426.189 19V0Z"
                    fill="#E2E9F1"
                />
            </svg>
            
            <svg className={st.bretton} data-hide-on-theme="light" width="427" height="74" viewBox="0 0 427 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M426.189 0H0.000370026L0 19C14.5672 19 28.6369 24.2996 39.5841 33.9101L62.5064 54.0335C77.1027 66.8475 95.8624 73.9137 115.285 73.9137L310.903 73.9137C330.326 73.9137 349.086 66.8475 363.682 54.0335L386.604 33.9102C397.552 24.2996 411.621 19 426.189 19V0Z"
                    fill="#12171E"/>
            </svg>
        </>
    )
}

export default OnPageJP