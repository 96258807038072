import {styled} from "@mui/material/styles";
import Slider from "@mui/material/Slider";


interface Props {
    max:number
    value:number
    danger?: boolean
    valuetext?:string
    secondary?:boolean
    denyValueLabel?:boolean
    railColor?:string
    height?: number,
    thumbInvisible?:boolean
    no_paddings?:boolean
    
}

const DummyRange = (props: Props)=> {
    
    const {
        max,
        danger,
        value,
        // valuetext,
        secondary,
        denyValueLabel,
        railColor,
        height,
        thumbInvisible,
        no_paddings
    } = props
    
    let local_sizes = height ? height : 12
    
    
    return (
        <LocalSlider
            disabled={false}
            marks={false}
            max={max}
            min={0}
            sx={{
                color: "#E7A500",
                height: "8px",
                overflow: "hidden",
                borderRadius:"100px",
                padding: no_paddings ? "0" : "12px 0",
                
                '@media (pointer: coarse)': {
                    padding: no_paddings ? "0" : "12px 0"
                },
                
                '& .MuiSlider-valueLabel': {
                    backgroundColor: "#E7A500",
                    color: "#000",
                    padding: "4px 8px"
                },
                
                '& .MuiSlider-rail': {
                    backgroundColor: railColor ? railColor : '#a0afc0',
                },
                
                '& .MuiSlider-thumb': {
                    height: local_sizes,
                    width: local_sizes,
                    display: thumbInvisible ? "none" : ""
                }
            }}
            valueLabelFormat={(value) => `${value.toFixed(2)}/${max.toFixed(2)}`}
            value={value}
            // value={0}
            valueLabelDisplay={denyValueLabel ? "off" : "auto"}
            className={"calculator_range_slide"}
        />
    )
}

const LocalSlider = styled(Slider)({
    
    '& .MuiSlider-track': {
        border: 'none',
        borderRadius: "100px",
        minWidth: "8px",
    },
    
    '& .MuiSlider-thumb': {
        height: "8px",
        width: "8px",
        transform: "translate(-8px, 0)",
        border: 'none',
        background: "transparent",
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        fontWeight: "700",
        background: 'unset',
        width: "auto",
        padding: "10px",
        height: "auto",
        borderRadius: '5px',
    },
    
    '& .MuiSlider-rail': {
        opacity: 1,
        borderRadius: "100px",
        backgroundColor: '#a0afc0',
    },
});


export default DummyRange