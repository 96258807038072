import {createTheme} from "@mui/material/styles";



export default createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#6C6F74',
                    marginRight: 10,

                    '&.MuiSvgIcon-fontSizeMedium' : {
                        marginRight: 0,
                        color: '#6C6F74',
                    },
                },
            },
        },

        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.datepicker': {
                        ['.MuiInputBase-root']: {
                            fontStyle: 'normal',
                            borderRadius: 60,
                            color: '#929396',
                            fontWeight: '700',
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                            textTransform: 'capitalize',
                            outline: 'none !important',
                            height: '100%',

                            '& input': {
                                padding: '20px 0px 8px 20px',
                                fontSize: 14,
                            },
                            '.MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                                // background: '#1F1B24'
                            },
                        },

                        [`${'html[data-theme="dark"]'} & .MuiInputBase-root`]: {
                            background: '#15191E',
                            boxShadow: '0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.15) inset',
                            border: '1px solid #2D3543',
                        },

                        [`${'html[data-theme="light"]'} & .MuiInputBase-root`]: {
                            background: '#E2E9F1',
                            boxShadow: '0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.15) inset',
                            border: '1px solid #B6C4D4',
                        },
                    }
                },
            },
        },

        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: '#efc436',

                    '&.candyQuestProgress': {
                        color: '#ca27fd',
                    },

                    '&.main_btn_loading': {
                        color: '#6C6F74'
                    },
                    '&.beetLoading': {
                        color: '#6C6F74',
                        width:40,
                        height:40
                    },

                    '&.necesar_progress_circular': {
                        width: '100% !important',
                        height: '100% !important',
                        display: 'block',
                        position: 'absolute',
                        top: '1px',
                        left: '-0.5px'
                    }
                },

                determinate: {
                    zIndex: '1 !important',
                }
            }
        },

        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#929396',
                    // background: 'red',
                    fontWeight: 400,
                    fontSize: 14,
                    minWidth: 20,

                    '&.Mui-selected': {
                        background: 'transparent',
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    },

                    [`${'html[data-theme="light"]'} &.Mui-selected`]: {
                        color: '#000'
                    },

                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }
            }
        },

        MuiPagination: {
            styleOverrides: {
                root: {
                    '&.paginatNumbers': {
                        minWidth: '300px'
                    },
                }
            }
        },

        MuiFormLabel: {
            styleOverrides: {
                root: {
                    // fontFamily: 'Montserrat',
                    // fontStyle: 'normal',
                    color: '#6C6F74',
                    paddingTop: 17,
                    paddingLeft: 12,
                }
            }
        },

        MuiInputLabel: {
            styleOverrides: {
                root:{
                    fontSize: 12,
                    fontWeight: 500,
                    fontFamily: '"Poppins", sans-serif',
                    textTransform: 'uppercase',
                    color: '#6C6F74',

                    '&.Mui-focused': {
                        color: 'white',
                    }
                }
            }
        },

        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#2D3543',
                    fontFamily: "Poppins",
                    borderRadius: '8px',
                    fontWeight: 500,
                    fontSize: 16,
                    minHeight: 32,
                    padding: '8px 12px',
                    display: 'flex',
                    alignItems: 'center',
                    WebkitAlignItems: 'center',
                    color: '#fff'
                },
                
                popper: {
                    zIndex: "10"
                }
            }
        },

        MuiSkeleton: {
            styleOverrides: {
                root: {
                    '&.simpleImageSkeleton': {
                        background: '#1e1b25',
                        width: '165px',
                        height: '165px',
                        borderRadius: '20px',
                    },

                    '&.carImageSkeleton': {
                        background: '#1e1b25',
                        width: '320px',
                        height: '165px',
                        borderRadius: '20px',

                        '@media (max-width: 1200px)': {
                            width: '210px',
                            height: '110px',
                        },

                        '@media (max-width: 770px)': {
                            width: '320px',
                            height: '165px',
                        },

                        '@media (max-width: 640px)': {
                            width: '250px',
                            height: '133px',
                        },

                        '@media (max-width: 500px)': {
                            width: '210px',
                            height: '110px',
                        }
                    },

                    '&.autoLgSkeleton': {
                        width: '673px',
                        height: '288px',
                        background: '#1e1b25',
                        borderRadius: '20px',
                    },

                    '&.autoSmSkeleton': {
                        width: '220px',
                        height: '125px',
                        borderRadius: '20px',
                        background: '#1e1b25',
                        transform: 'translateX(-15px)',
                    },

                    '&.cardSkeleton': {
                        background: '#383838',
                        borderRadius: '5px',
                        marginRight: '20px',
                        width: '100%',
                        height: '100%',
                        aspectRatio: '100000/144421',
                        transform: 'scale(0.95)',
                        display: 'block',
                        '@media (max-width:991px)' : {
                            background: '#383838',
                            borderRadius: '5px',
                            display: 'block',

                        }
                    },

                    '&.categHeadingSkeleton': {
                        background: '#383838',
                        borderRadius: '5px',
                        width: '326.02px',
                        height: '36px',
                        display: 'block',

                        '@media (max-width:575px)': {
                            width: '269px',
                        },

                        '@media (max-width:361px)': {
                            width: '231px',
                        }
                    },

                    '&.categLeftFilterSkeleton': {
                        background: '#383838',
                        height: '56px',
                        borderRadius: '100px',
                        minWidth: '179px',
                        display: 'block',

                    },

                    '&.categRightFilterSkeleton': {
                        background: '#383838',
                        height: '56px',
                        borderRadius: '100px',
                        width: '252px',
                        display: 'block',
                    },

                    '&.themeCategorySkeleton': {
                        background: '#383838',
                        width: '100%',
                        height: '90%',
                        display: 'block',
                        borderRadius: '10px',

                    },

                    '&.tableSkeleton': {
                        height: '56px',
                        width: '100%',
                        display: 'block !important',

                        '&.tableSkeletonLight': {
                            background: '#17141B !important',
                        }
                    },

                    '&.skeletonJpCard': {
                        width: '292px',
                        height: '380px',
                        borderRadius: "24px",

                        '@media (max-width: 991px)': {
                            width: '125px',
                            height: '185px',
                            marginRight: '8px' ,
                        }
                    },

                    '&.skeletonGameCard': {
                        borderRadius: '5px',
                        marginRight: '20px',
                        width: '240px !important',
                        height: '356px',
                        transform: 'scale(0.90) translateX(3px)',
                        display: 'block',


                        '@media (max-width:991px)' : {
                            borderRadius: '5px',
                            width: '133px !important',
                            height: '212px',
                            display: 'block',
                        }
                    },

                    [`${'html[data-theme="light"]'} &.skeletonJpCard`]: {
                        background: '#97a0ab',
                    },

                    [`${'html[data-theme="dark"]'} &.skeletonJpCard`]: {
                        background: '#212632',
                    },

                    [`${'html[data-theme="light"]'} &.winnersSkeleton`]: {
                        background: '#97a0ab',
                    },

                    [`${'html[data-theme="dark"]'} &.winnersSkeleton`]: {
                        background: '#212632',
                    },

                    [`${'html[data-theme="light"]'} &.skeletonGameCard`]: {
                        background: '#97a0ab',
                    },

                    [`${'html[data-theme="dark"]'} &.skeletonGameCard`]: {
                        background: '#212632',
                    },
                    
                    [`${'html[data-theme="dark"]'} &.mapSkeleton`]: {
                        background: '#181E2A',
                    },
                    
                    [`${'html[data-theme="light"]'} &.mapSkeleton`]: {
                        background: '#B6C4D4',
                    },

                    '&.mapSkeleton' : {
                        width: '764.37px',
                        height: '1620.54px',
                        borderRadius: '10px',
                        position: 'absolute',
                        top: 0,
                        left: 0,

                        '@media (max-width: 890px)': {
                            // width: '428px',
                            minWidth: '428px',
                            minHeight: '907.3px'
                        },
                    },

                    '&.candyMapSkeleton' : {
                        width: '670px',
                        height: '2420px',
                        background: '#2e0738',
                        borderRadius: '10px',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    },


                    '&.winnersSliderSkeleton': {
                        width: '222px',
                        height: '16px',
                        borderRadius: '10px',
                        marginLeft: '25px',
                        marginBottom: '10px',

                        '@media (max-width: 575px)': {
                            width: '342px',
                            height: '29px',
                            borderRadius: '10px',
                            marginBottom: '10px',
                        },

                    }
                }
            }
        },

        MuiButtonBase: {
            styleOverrides: {
                root:{
                    [`${'html[data-theme="dark"]'} &.MuiButton-root`]:{
                        color: '#212632'
                    },
                    [`${'html[data-theme="light"]'} &.MuiButton-root`]:{
                        color: '#212632'
                    }
                }

            }
        },

        // MuiYearPicker:{
        //     styleOverrides:{
        //         root: {
        //             [`${'html[data-theme="dark"]'} &.PrivatePickersYear-button.Mui-selected`]:{
        //                 backgroundColor: '#212632'
        //             },
        //             [`${'html[data-theme="light"]'} &.PrivatePickersYear-button.Mui-selected`]:{
        //                 backgroundColor: '#8d98a4'
        //             }
        //         }
        //     }
        // }

    }
});
