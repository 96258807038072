import styles from 'themes/spins/styles/accountMenu.module.scss'
import strings from "utils/strings";
import Link from 'next/link';
import clsx from 'clsx'
import useAuth from "hooks/auth";
import {useDispatch, useSelector} from 'react-redux';
import {openDeposit} from 'store/modals/actions';
import a from 'themes/spins/styles/Header.module.scss';
import {useRouter} from 'next/router'
import UserValidationIconSpins from 'themes/spins/svg/userValidationIconSpins'
import DocumentsIconSpins from 'themes/spins/svg/documentsIconSpins'
import PersonalInfoIconSpins from 'themes/spins/svg/personalInfoIconSpins'
import ActivitateIconSpins from  'themes/spins/svg/activitateIconSpins'
import TranzactiiIconSpins from 'themes/spins/svg/tranzactiiIconSpins'
import PariuriIconSpins from 'themes/spins/svg/pariuriIconSpins'
import DepuneriiconSpins from 'themes/spins/svg/depuneriiconSpins'
import RetrageriIconSpins from 'themes/spins/svg/retrageriIconSpins'
import PromotionIcon from "themes/spins/svg/promotionIcon";
import JocresponsabilIconSpins from "themes/spins/svg/jocresponsabilIconSpins";
import ChangePasswordSpins from "themes/spins/svg/changePasswordiconSpins";
import AccountIconSpins from "themes/spins/svg/accountIconSpins";
import TermsIconSpins from "themes/spins/svg/termsIconSpinsSidebar";
import PoliticaIconSpins from "themes/spins/svg/politicaIconSpins";
import SupportIconSpins from "themes/spins/svg/supportIconSpins";
import AttentionRedIconSpins from "themes/spins/svg/attentionRedIconSpins";
import React, {useState, useEffect} from "react";
import VerificationStatus from "@/AbsoluteComponents/interfaces/VerificationStatus";
import {setFromAccPg, showAccountSideBar, showTermsSideBar} from "@/AbsoluteComponents/store/common/actions";
import Button from 'themes/spins/small_ui/button'
import {ClickAwayListener} from "@mui/base";
import {ChevronLeft} from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";
import AvailableBonusIcon from "@/AbsoluteComponents/themes/spins/svg/available_bonus_icon";
import ActiveBonusIcon from "@/AbsoluteComponents/themes/spins/svg/active_bonus_icon";
import FinishedBonusItem from "@/AbsoluteComponents/themes/spins/svg/finished_bonus_item";


interface Props {
    item:any
}
const BurgerItem:React.FC<Props> = ({item}) => {
    
    const hoverColor = item.hover_color
    const hasSubmenu = item.submenu.length
    const id = item.name ? "burger_" + item.name : ""
    
    const router = useRouter()
    
    const [initColor, setInitColor] = useState<string>('')
    const [openSubmenu, setOpenSubmenu] = useState<boolean>(false)
    
    const getAllItemsHeight = ():number => {
        const links = document.getElementsByClassName('burger_link'+item.label)
        const arr = Array.from(links)
        let totalHeight:number = 16
        
        for(let x of arr) {
            let h = x.getBoundingClientRect().height
            if(h) {
                totalHeight += h + 8
            }
        }
        
        return totalHeight
    }
    
    const addRemoveHover = (remove:boolean, dropdown:HTMLElement) => {
        if(hoverColor?.length && id.length && dropdown) {
            
            if(!remove) {
                
                dropdown.style.background = hoverColor
            } else {
                
                dropdown.style.background = initColor
            }
        }
        
        if(!hoverColor && id.length && dropdown) {
            
            if(!remove) {
                dropdown.style.background = "#d7ded8"
            } else {
                
                dropdown.style.background = initColor
            }
        }
    }
    
    useEffect(() => {
        
        let dropdown = document.getElementById(id)
        
        
        if (dropdown) {
            dropdown.addEventListener("mouseenter", () => addRemoveHover(false, dropdown as HTMLElement));
            dropdown.addEventListener("mouseleave", () => addRemoveHover(true, dropdown as HTMLElement));
            let init_color = dropdown.style.background
            setInitColor(init_color)
        }
        
        // Cleanup the event listener on component unmount
        return () => {
            if (dropdown) {
                dropdown.removeEventListener("mouseenter", () => addRemoveHover(false, dropdown as HTMLElement));
                dropdown.removeEventListener("mouseleave", () => addRemoveHover(true, dropdown as HTMLElement));
            }
        };
        
    }, []);
    
    useEffect(() => {
        let found:boolean = false
        item.submenu.map((x:any) => {
            let isCurrent = router.pathname == x.path
            if(isCurrent) found = true
        });
        
        if(found) {
            setOpenSubmenu(true)
        } else {
            setOpenSubmenu(false)
        }
        
    }, [router]);
    
    
    return (
        <div className={styles.submenu_item}>
            
            <div
                id={id}
                onClick={() => {
                    if(hasSubmenu) {
                        setOpenSubmenu((open) => !open)
                        
                    } else {
                        router.push("/"+item.name)
                    }
                }}
                className={clsx(
                    'sidebarAccountMode__title',
                    'theme_sidebarAccountMode__title',
                    styles.label,
                    openSubmenu && styles.opened
                )}
            >
                {item.label}
                <ChevronLeft />
            </div>
            
            {hasSubmenu ? (
                
                <div style={{maxHeight: `${openSubmenu ? getAllItemsHeight() : 0}px`}} className={clsx(styles.submenu, openSubmenu && styles.opened)}>
                    {item.submenu.map((x:any, i:number) => {
                        return x.visible? (
                            <Link
                                key={id+x.label+"submenu"}
                                href={x.path}
                                style={{margin: '0 8px 8px'}}
                                className={clsx(
                                    (router.asPath.search(x.path) > -1 ) && 'clickedLink',
                                    'burger_link'+item.label,
                                    styles.links
                                )}
                                onClick={x.hasOwnProperty("onClick") ? x.onClick : () => {}}
                            >
                                {x.icon}
                                <div className={'sidebarAccountMode__text'}>{x.label}</div>
                            </Link>
                        
                        ) : null
                    })}
                </div>
            
            
            ) : null}
        </div>
    )
}



const SpinsAccountSideBarVIew = () => {
    
    const auth = useAuth();
    const user = auth.getUser();
    const router = useRouter();
    const currentRoutePath = router.asPath;
    const dispatch = useDispatch();
    const opened:Array<VerificationStatus> = useSelector((state:any) => state.account.validation_opened);
    const isMobile:boolean = useSelector((state:any) => state.common.isMobile);
    const smartico_props = useSelector((state:any) => state.account.smarticoProps)
    
    const verificationStatus:Array<VerificationStatus> = useSelector((state:any) => state.account.verificationStatus);
    
    let allVerified:boolean = true;
    
    verificationStatus.map(item => {
        if (!item.verified) allVerified = false;
    });
    
    const menu = [
        {
            label: strings["acc"],
            submenu: [
                {
                    label: strings['verificarea_contului'],
                    path: "/account/account-validation",
                    visible: opened,
                    icon: <AttentionRedIconSpins />
                },
                {
                    label: strings['documentele_mele'],
                    path: "/account/my-documents",
                    visible: true,
                    icon: <DocumentsIconSpins />
                },
                {
                    label: strings['informatii_personale'],
                    path: "/account/profile",
                    visible: true,
                    icon: <PersonalInfoIconSpins />
                },
            ],
        },
        
        {
            label: strings["bonuses"],
            submenu: [
                {
                    label: strings['bonusuri_disponibile'],
                    path: '/account/transactions/campaigns/available',
                    visible: true,
                    icon: <AvailableBonusIcon />
                },
                {
                    label: strings['bonusuri_active'],
                    path: '/account/transactions/campaigns/active',
                    visible: true,
                    icon: <ActiveBonusIcon />
                },
                {
                    label: strings['bonusuri_incheiate'],
                    path: "/account/transactions/campaigns/finished",
                    visible: true,
                    icon: <FinishedBonusItem />
                },
            ],
        },
        
        {
            label: strings['istoric'],
            submenu: [
                {
                    label: strings['activitate'],
                    path: "/account/activity",
                    visible: true,
                    icon: <ActivitateIconSpins />
                },
                {
                    label: strings['tranzactii_joc'],
                    path: "/account/transactions/game",
                    visible: true,
                    icon: <TranzactiiIconSpins />
                },
                {
                    label: strings['tranzactii_pariuri'],
                    path: "/account/transactions/betting",
                    visible: true,
                    icon: <PariuriIconSpins />
                },
                {
                    label: strings['depuneri'],
                    path: "/account/transactions/deposit",
                    visible: true,
                    icon: <DepuneriiconSpins />
                },
                {
                    label: strings['retragere'],
                    path: "/account/transactions/withdrawal",
                    visible: true,
                    icon: <RetrageriIconSpins />
                },
                {
                    label: strings['promotions'],
                    path: "/account/transactions/promotions",
                    visible: true,
                    icon: <PromotionIcon />
                },
            ],
        },
        
        {
            label: strings['setari'],
            submenu: [
                {
                    label: strings['joc_responsabil'],
                    path: "/account/responsable-game",
                    visible: true,
                    icon: <JocresponsabilIconSpins />,
                    className: 'theme_sidebarAccountMode__title', // Add additional properties as needed
                    textClassName: 'sidebarAccountMode__text'
                },
                {
                    label: strings['schimbare_parola'],
                    path: "/account/password-change",
                    visible: true,
                    icon: <ChangePasswordSpins />,
                    className: 'theme_sidebarAccountMode__title',
                    textClassName: 'sidebarAccountMode__text'
                },
                {
                    label: strings['cont'],
                    path: "/account/profile-close",
                    visible: true,
                    icon: <AccountIconSpins />,
                    className: 'theme_sidebarAccountMode__title',
                    textClassName: 'sidebarAccountMode__text'
                },
            ],
        },
        
        {
            label: strings['support'],
            submenu: [
                {
                    label: strings['termeni_și_condiții'],
                    path: "/terms-and-conditions",
                    visible: true,
                    icon: <TermsIconSpins />,
                    onClick: () => {
                        dispatch(setFromAccPg(true));
                    }
                },
                {
                    label: strings['politica_de_confidențialitate'],
                    path: "/privacy-policy",
                    visible: true,
                    icon: <PoliticaIconSpins />,
                    onClick: () => {
                        dispatch(showTermsSideBar(true));
                        dispatch(showAccountSideBar(false));
                        dispatch(setFromAccPg(true));
                    }
                },
                {
                    label: strings['asistenta'],
                    path: "/account/support",
                    visible: true,
                    icon: <SupportIconSpins />
                },
            ],
        },
    
    ]
    
    return (
        <div >
            {user &&
                <div style={{margin: '8px 16px 0'}} className={clsx(a.sidebar__btn, 'sidebar__btn__account', 'theme_searchInput_header')}>
                    
                    <div className={clsx(styles.sidebar__btnAside, styles.btnBlock)}>
                        
                        <Button
                            outlined
                            onClick={() => auth.openWithdrawPopup()}
                            text={strings['retragere']}
                        />
                        
                        <Button
                            onClick={() => dispatch(openDeposit(true))}
                            text={strings['depunere']}
                        />
                    
                    </div>
                    
                    <div className={clsx(a.sidebar__userBlock,)} style={{marginTop: 0}}>
                        <Tooltip
                            title={((!allVerified || opened) && !isMobile) ? "Contul nu este verificat" : ""}
                            placement={'bottom-end'}
                        >
                            <div
                                style={{
                                    border:`${(!allVerified || opened) && '2px solid #f00 !important'}`,
                                    
                                }}
                                className={clsx(a.sidebar__user, (!allVerified || opened) && 'notVerified', 'sidebar__user__account', 'auth', 'inSidebar')}
                            >
                                
                                { (typeof smartico_props === "object" && Object.keys(smartico_props).length && smartico_props.hasOwnProperty("avatar_id")) ? (
                                    <img style={{cursor: "pointer"}} onClick={() => {SmarticoClass.changeAvatar()}} src={smartico_props?.avatar_id} alt={""} width={100} height={100} />
                                ) : (
                                    <div className={clsx(a.sidebar__userName, "theme_text_mainColor")}>
                                        {user?.firstName[0]}{user?.lastName[0]}
                                    </div>
                                )}
                            
                            </div>
                        </Tooltip>
                        
                        
                        <div className={a.sidebar__userData}>
                            <div
                                className={clsx(a.sidebar__userFullName, 'theme_textColor_secondary')}>{user?.firstName} {user?.lastName}</div>
                            <div className={a.sidebar__balanceBlock}>
                                <div className={clsx(a.sidebar__balance, 'theme__headerContainer__list')}>{strings['balanta']}</div>
                                <div className={clsx(a.sidebar__balanceItem, 'theme_textColor_secondary')}>
                                    {auth.getUserBalance().toFixed(2)}
                                    <span
                                        className={clsx(styles.sidebar__currence, 'theme_text_mainColor')}
                                    >
                                        {auth.getCurrency()}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            }
            
            <div
                className={clsx('sidebarAccountMode', 'theme__headerContainer__listSideBar', 'theme_sidebar__links__account')}>
                
                
                {menu.map((x: any, i: number) => {
                    return (
                        <BurgerItem key={"acc_menu_item" + i} item={x}/>
                    )
                })}
            
            </div>
            
            <div className={a.sidebar__socialBlock} style={{paddingLeft: 40, marginTop: 50}}>
            
            </div>
        
        </div>
    )
}

export default SpinsAccountSideBarVIew;