import {FC, useRef} from "react";
import {useRouter} from 'next/router';
import useAuth from 'hooks/auth';
import styles from 'themes/spins/styles/card.module.scss';
import GameInterface from "interfaces/game";
import LazyImage from "ui/LazyImage";
import clsx from 'clsx';
import {useDispatch, useSelector} from 'react-redux';
import FavoriteButton from "./favoriteButton";
import {useState, useEffect} from 'react';
import {openFullGameModal} from "store/modals/actions";
import PlaySpins from 'themes/spins/svg/playSpins';
import string from 'utils/strings';
import Game from 'utils/game';
import RtpHotSpins from 'themes/spins/svg/rtpHotSpins';
import RtpColdSpins from 'themes/spins/svg/rtipColdSpins';
import FavoriteIconGame from 'themes/spins/svg/favoriteIconGame'
import useFavorite from "@/AbsoluteComponents/hooks/favourite_games";
import useCommon from "@/AbsoluteComponents/hooks/common";
import {bingo_path} from "@/AbsoluteComponents/themes/spins/config/constants";
import CMS from "@/AbsoluteComponents/utils/cms";


interface Props {
    game_thumbnail?: string;
    width?: number;
    fromTop?:boolean
    game?: GameInterface;
    homeSliderItem?: boolean,
    minimize?: boolean,
    hideDetails?: boolean,
    disableLazyLoading?: boolean,
    disableDemo?: boolean,
    json?: any,
    onCardClick?(game: any, gameUrl: string): void,
    page?: string,
    className?: string,
    providerName?: string,
    demoButtonStyle?: string,
    inGrid?:boolean
    hide_inner?:boolean
    
}


const Card: FC<Props> = ({
                             demoButtonStyle,
                             providerName,
                             width,
                             game,
                             homeSliderItem,
                             minimize,
                             hideDetails,
                             disableLazyLoading,
                             disableDemo,
                             onCardClick,
                             page,
                             className,
                             inGrid,
                             fromTop,
                             hide_inner
                         }) => {
    const dispatch = useDispatch();
    const auth = useAuth();
    const showSearchBar: boolean = useSelector((state: any) => state.common.showSearchBar);
    const elem = useRef<any>(null)
    const common_hook = useCommon()
    const gameProvider = Game.getVendorsList();
    const router = useRouter();
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const fav = useFavorite()
    const isFav = game? fav.isFavorite(game) : false
    let imageGame:string = '';
    const generalSettings = CMS.getGenSettingsJSON();
    const data = generalSettings ? CMS.getAllFields(generalSettings) : {}
    const alt_text = data.games_alt_text
    const game_alt_text = alt_text.replace("{{game_name}}", game?.friendlyName)
    const isMobile:boolean = useSelector((state:any) => state.common.isMobile);

    if (game?.gameAssets.hasOwnProperty('thumbnail')) {
        imageGame = game.gameAssets.thumbnail;
    }
    

    const GameProviderName = () => {
        let name = '';

        gameProvider.map(item => {
            if (item.providerId === game?.provider) {
                name = item.name
            }

        })
        
        return name;
    }

    const loadImage = (src: string) => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = src;

            img.onload = () => {
                resolve(true);
            }

            img.onerror = () => {
                resolve(false);
            }
        })
    }

    useEffect(() => {
        if(inGrid) {
            common_hook.observe(elem.current, 700)
        }
        
        const loadImages = async () => {

            let imageArr: Array<string> = [imageGame]
            let arr = []

            await loadImage(imageArr[0])
            arr.push(imageArr[0])
            if (arr.length === 1) {
                setImageLoaded(true)
            }
        }

        loadImages()

    }, [])
    
    
    
    const openGamePopup = (e: any) => {
        if(window.location.pathname === bingo_path && game) {
            return
        }
        
        e.stopPropagation();
        if (game) {
            dispatch(openFullGameModal(game));
        }
    }

    const clickHandle = (url: string) => {
        if (showSearchBar && typeof onCardClick === "function") onCardClick(game, url);
    }
    
    
    
    return (
        <div ref={elem} className={clsx((game?.hotRTP || game?.coldRTP) && 'cardWrapper_rtpHot',
            (game?.hotRTP || game?.coldRTP) && 'theme_cardWrapper_rtpHot')
        }>
            <div
                id={'singleGameCard'}
                style={{maxWidth: `${page === 'live-casino' ? 'unset' : width}` || undefined, marginRight:  (game?.hotRTP || game?.coldRTP) ? '0': 'unset' }}
                className={
                    clsx(
                        styles.card,
                        minimize &&  'blockContainer',
                        (!minimize &&  (!game?.hotRTP || !game?.coldRTP)) && 'blockContainerFull',
                        className && className,
                        homeSliderItem && styles.card__home_slider_item,
                        minimize && styles.card__minimize,
                        (homeSliderItem && minimize) && styles.card__min_right,
                        (homeSliderItem && !minimize) && styles.card__regular_right,
                        page === 'live-casino' && styles.casinoCard
                    )
                }

            >
                <div className={clsx('hoverEffect')}></div>
                <div className={clsx('hoverEffect2')}></div>

                {!disableLazyLoading ? (
                    <LazyImage src={imageGame} alt={game_alt_text || ''}/>
                ) : (
                    <img loading="lazy" className={className} src={imageGame} alt={game_alt_text || ''}/>
                )}


                <div className={'cardBlock'} onClick={openGamePopup}/>
                
                {!hide_inner && (
                    <>
                        <div className={clsx(styles.card__inner, hideDetails && styles.hideDetailsInner)}>
                            
                            {game &&
                                <button
                                    type={'button'}
                                    className={styles.card__play}
                                    onClick={(e: any) => auth.runGame(game, false, clickHandle, e)}
                                    aria-label={"play"}
                                >
                                    <div>
                                        <PlaySpins/>
                                    </div>
                                </button>
                            }
                            
                            <h5 className={styles.card__title}>
                                {game?.friendlyName}
                            </h5>
                            
                            {!fromTop && (
                                <div className={clsx(hideDetails && styles.hideDetails)}>
                                    
                                    {game?.providerImage &&
                                        <img
                                            loading="lazy"
                                            className={styles.card__providerImage}
                                            src={game.providerImage}
                                            alt={game?.friendlyName}
                                        />
                                    }
                                
                                </div>
                            )}
                            
                            {(!disableDemo && game?.demoAvailable) &&
                                <div
                                    onClick={(e: any) => {
                                        auth.runGame(game, true, clickHandle, e)
                                    }}
                                    className={clsx(styles.providerName_card, demoButtonStyle && demoButtonStyle)}
                                >
                                    Demo
                                </div>
                            }
                        
                        
                        </div>
                        
                        {auth.isLoggedIn() && (
                            <button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    if (game) {
                                        fav.addToFavorites(game, isFav)
                                    }
                                }}
                                className={clsx(styles.card__favButton, isFav && "theme_text_yellow")}
                            >
                                <FavoriteIconGame/>
                            </button>
                        )}
                    </>
                
                )}
            
            </div>
            
            {game?.hotRTP && <div className={clsx(styles.card__rtp, styles.card__rtp__hot)}>
                <RtpHotSpins/>
                <span className={clsx(styles.card__rtp__hot_data, "theme_white_dark_text")}>{game.rtp}%</span>
            </div>}
            {game?.coldRTP && <div className={clsx(styles.card__rtp, styles.card__rtp__cold)}>
                <RtpColdSpins/>
                <span className={clsx(styles.card__rtp__hot_data, "theme_white_dark_text")}>{game.rtp}%</span>
            </div>}
        </div>
    )
}
export default Card;