import React, {useState, FC, useEffect, createRef} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import styles from "@/template/styles/JpFever.module.scss";
import clsx from 'clsx'
import {Navigation} from "swiper";
import {Pagination} from "swiper";
import CMS from "utils/cms";
import PaginationArrow from "ui/paginationArrow";
import Skeleton from '@mui/material/Skeleton';
import {useDispatch, useSelector} from 'react-redux'
import JackpotCardDynamic from "./jackpot_card_dynamic"
import AllJpInterface from "@/AbsoluteComponents/interfaces/all_jp_interface";
import {jackpot_types, jackpot_types_old} from "@/AbsoluteComponents/interfaces/types";
import QuestionIcon from "@/template/svg/questionIcon";
import JackpotNewFunctionModal from "@/template/ui/modals/jackpot_new_function_modal";
import JPInit from "@/AbsoluteComponents/jp_init";
import ArrowRight from "@/template/svg/arrow-right";

interface Props {
    json: any
    index?:any
}


const JackpotsFever:FC<Props> = (props) => {
    
    // const {index} = props
    
    // if (index >= lazy_loading_offset) {
    //     return (
    //         <LazyComponentWrap>
    //             <Core { ... props}/>
    //         </LazyComponentWrap>
    //     )
    // } else {
        return <Core { ... props}/>
    // }
}

export default JackpotsFever;

const Core: React.FC<Props> = ({json}) => {
    
    const [swiper, setSwiper] = useState<any>();
    const [modal_open, set_modal_open] = useState<boolean>(false);
    const data = CMS.getAllFields(json)
    const all_jackpots:AllJpInterface | undefined = useSelector((state:any) => state.common.all_jackpots);
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isBeginning, setIsBeginning] = useState<boolean>(true);
    const elem:any = createRef();
    
    function getNewJackpotType(oldValue: string): string | undefined {
        const entries = Object.entries(jackpot_types_old);
        for (const [key, value] of entries) {
            if (value === oldValue) {
                return jackpot_types[key as keyof typeof jackpot_types];
            }
        }
        return undefined;
    }
    
    const sortedJackpots = () => {
        let cms_jp_list = data.jackpots_array
        let res:any = []
        
        if(Array.isArray(cms_jp_list) && cms_jp_list.length) {
            const sorted = cms_jp_list.sort((a, b) => Number(a.order) - Number(b.order))
            
            

            sorted.forEach((x:any) => {
                
                let name = x.name as jackpot_types
                
                if(x.name.includes(" API")) {
                    name = getNewJackpotType(x.name) as jackpot_types
                }
                
                if(typeof all_jackpots !== "undefined" && all_jackpots[name] && x.visible) {
                    res.push({...x, ...{jackpot: all_jackpots[name]}})
                    
                } else {
                    res.push({...x, ...{jackpot: undefined}})
                    
                }
            })
        }
        
        return res
    }
    
    const jp_list = sortedJackpots()
    // console.log("jp_list", jp_list)
    
    let skeletonLength = 10
    let skeletonArray: Array<any> = []
    
    for (let i = 0; i < skeletonLength; i++) {
        skeletonArray.push(i)
    }


    return (
        <>
            <JPInit />
            
            <div
                ref={elem}
                id={'jackpotBlock'}
                className={clsx(
                    styles.sliderContainer,
                    'theme-slider',
                    'sliderContainer__jackpot',
                    'slideClass',
                    'liveJPCarousel'
                )}
            >
                
                <div className={clsx('gamesSlider-box', 'title_lifeJackpot')}>
                    
                    <div className={styles.title}>
                        <h2 className={clsx('gamesSlider__title', "theme_white_dark_text")}>
                            {data.title}
                        </h2>
                        
                        <button
                            aria-label={"jackpot info"}
                            onClick={() => {
                                set_modal_open(true)
                            }}
                        >
                            <QuestionIcon/>
                        
                        </button>
                    </div>
                    
                    
                    <div className={'buttons-wrapperSlide'}>
                        
                        <div className={'arrowButtons-wrapperSlide'}>
                            <button
                                aria-label={"slide"}
                                disabled={isBeginning}
                                onClick={() => {
                                    swiper.slidePrev()
                                    setIsEnd(false)
                                }}
                                className={clsx('btnSlider-first', 'theme_btnSlider_arrow', isBeginning && 'locked')}
                            >
                                <span className={'theme__svg'}><ArrowRight/></span>
                            </button>
                            
                            <button
                                aria-label={"slide"}
                                disabled={isEnd}
                                onClick={() => {
                                    swiper.slideNext()
                                    setIsBeginning(false)
                                }}
                                className={clsx('btnSlider-second', 'theme_btnSlider_arrow', isEnd && 'locked')}
                            >
                                <span className={'theme__svg'}><ArrowRight/></span>
                            </button>
                        </div>
                    
                    </div>
                </div>
                
                {/*<div className={styles.title}>*/}
                
                {/*</div>*/}
                
                
                <Swiper
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Navigation]}
                    className={clsx(styles.slider, 'swiper-wrapper', 'swiperJackpotInner')}
                    spaceBetween={24}
                    slidesPerView='auto'
                    onSwiper={(sw:any) => {
                        setSwiper(sw)
                    }}
                    onReachBeginning={() => setIsBeginning(true)}
                    onReachEnd={() => setIsEnd(true)}
                    onSlideNextTransitionStart={() => {setIsBeginning(false)}}
                    onSlidePrevTransitionStart={() => {setIsEnd(false)}}
                >
                    
                    <PaginationArrow onClick={() => swiper.slideNext()}/>
                    <PaginationArrow onClick={() => swiper.slidePrev()} rightArrow/>
                    
                    {jp_list.length ? jp_list?.map((jp: any, i: number) => {
                        
                        let firstSlide: boolean = jp_list.indexOf(jp) === 0
                        
                        return (jp.jackpot) ? (
                            <SwiperSlide
                                key={'jackpot' + i + jp.name}
                                className={clsx(styles.slider__slide, firstSlide && styles.first)}
                            >
                                <JackpotCardDynamic
                                    api_id={jp.api_id}
                                    jackpot={jp.jackpot}
                                    url={jp.url}
                                />
                            </SwiperSlide>
                        ) : (
                            <SwiperSlide key={'key' + i}
                                         className={clsx(styles.slider__slide, firstSlide && styles.first)}>
                                <Skeleton
                                    variant="rectangular"
                                    className={'skeletonJpCard'}
                                    animation="wave"
                                />
                            </SwiperSlide>
                        )
                        
                    }) : skeletonArray?.map((i) => {
                        
                        let firstSlide: boolean = skeletonArray.indexOf(i) === 0
                        
                        return (
                            <SwiperSlide key={'key' + i}
                                         className={clsx(styles.slider__slide, firstSlide && styles.first)}>
                                <Skeleton
                                    variant="rectangular"
                                    className={'skeletonJpCard'}
                                    animation="wave"
                                />
                            </SwiperSlide>
                        )
                    })}
                
                </Swiper>
            </div>
            
            <JackpotNewFunctionModal
                data={data}
                open={modal_open}
                closeModal={() => {
                    set_modal_open(false)
                }}
            />
        </>
    
    )
}

