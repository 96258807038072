const strings: any = {

    "live_rtp_page_title":"Live RTP | SPIN.ro",
    "favorites_page_title":"Jocuri Favorite | SPIN.ro",
    "available_offers_page_title":"Bonusurile mele | SPIN.ro",
    "my_documents_page_title":"Documentele Mele | SPIN.ro",
    "account_verification_page_title":"Bonusurile mele | SPIN.ro",
    "theme_available_offers":"Verificarea Contului | SPIN.ro",
    "profile_page_title":"Informații Personale | SPIN.ro",
    "activity_page_title":"Istoric Activitate  | SPIN.ro",
    "all_providers_page_title":"Furnizori de jocuri | SPIN.ro",
}

export default strings

