import React from "react";
import Modal from "react-modal";
import clsx from "clsx";
import st from "@/template/styles/JpFever.module.scss";
import ModalLayout from "@/template/ui/modal_layout";
import CloseIcon from "@/template/svg/closeicon";
import strings from "@/AbsoluteComponents/utils/strings";
import Button from "@/template/small_ui/button";
import BurningBox from "@/template/small_ui/BurningBox";

interface Props {
    open:boolean
    closeModal():void
    data:any
}

const JackpotNewFunctionModal:React.FC<Props> = ({open, closeModal, data}) => {
    

    function removeHtmlTags(str:string) {
        return str?.replace(/<\/?[^>]+(>|$)/g, "") || "";
    }
    
    const {popup_description, primary_description, popup_second_description, popup_title } = data
    
    const list = [
        {
            is_image: false,
            on_fire: false,
            value: primary_description
        },
        {
            is_image: true,
            on_fire: false,
            value: "cool_start"
        },
        {
            is_image: true,
            on_fire: false,
            value: "warm_up"
        },
        {
            is_image: true,
            on_fire: false,
            value: "blazing_hot"
        },
        {
            is_image: true,
            on_fire: false,
            value: "on_fire"
        },
        {
            is_image: true,
            on_fire: true,
            value: "on_fire"
        },
    ]
    
    return (
        <Modal
            ariaHideApp={false}
            isOpen={open}
            className={clsx(st.modal, 'ReactModal__Deposit', "theme_background_1")}
            onRequestClose={closeModal}
        >

            <ModalLayout
                title={popup_title}
                onClose={closeModal}
            >
                
                <div className={st.inner}>
                    
                    
                    <div className={st.body}>
                        <div dangerouslySetInnerHTML={{__html: popup_description}}/>
                        
                        
                        <div className={st.grid}>
                            {list.map((x: any, i: number) => {
                                
                                const {
                                    is_image,
                                    on_fire,
                                    value
                                } = x
                                
                                return (
                                    <div
                                        key={`inf_list_${i}`}
                                        className={clsx(st.grid_item, is_image && st.is_image)}
                                    >
                                        {is_image ? (
                                            <img src={`spins/images/JackpotsLive/statuses/${value}.webp`} alt={value}/>
                                        ) : (
                                            <span className={st.yellow}>
                                                {value}
                                            </span>
                                        )}
                                        
                                        {on_fire ? (
                                            <BurningBox
                                                main_box_class={st.burn}
                                                glow_class={st.glow}
                                                // box_class={st.burn}
                                                dark_box_class={st.burn_inner}
                                                glowing
                                            
                                            >
                                                <></>
                                            </BurningBox>
                                        ) : null}
                                    </div>
                                )
                            })}
                        </div>
                        
                        <div dangerouslySetInnerHTML={{__html: popup_second_description}}/>
                    
                    </div>
                    
                    <div className={st.footer}>
                    <Button
                            text={"am înțeles"}
                            onClick={closeModal}
                        />
                    </div>
                
                </div>
            
            </ModalLayout>
        
        </Modal>
    )
    
}

export default JackpotNewFunctionModal